const faqs = [
  {
    question: "Documentación y permisos para viajar a Estados Unidos",
    answer: (
      <div>
        <p>- Pasaporte: El primer requisito es tener un pasaporte válido. Asegúrate de que tu pasaporte tenga una vigencia de al menos seis meses a partir de la fecha de entrada a Estados Unidos. Si tu pasaporte está próximo a vencer, es recomendable renovarlo antes de tu viaje.</p><br/>
        <p>- Visa: La mayoría de los ciudadanos mexicanos necesitan obtener una visa de no inmigrante para ingresar a Estados Unidos. No obstante, hay casos excepcionales, como el Programa de Exención de Visa (Visa Waiver Program, VWP), que brinda la posibilidad a ciudadanos de determinados países, incluyendo México, de viajar a Estados Unidos por motivos turísticos o de negocios durante un máximo de 90 días sin requerir una visa previa.</p><br/>
        <p>- Autorización ESTA: Si planeas viajar a Estados Unidos bajo el VWP, deberás solicitar la autorización ESTA en línea antes de tu viaje. Este proceso es rápido y sencillo, y requiere completar un formulario en línea con información personal y detalles del viaje. Asegúrate de solicitar la autorización ESTA con suficiente anticipación, ya que es obligatoria para ingresar al país.</p><br/>
        <p>- Boletos de avión: Para ingresar a Estados Unidos, es recomendable contar con los boletos de avión de ida y vuelta. Los agentes de inmigración pueden solicitar evidencia de tu plan de regreso a México como parte de los requisitos de entrada. Además, tener los boletos de avión confirmados también ayuda a demostrar que tienes intenciones de salir del país antes de que expire tu estadía autorizada.</p><br/>
        <p>- Comprobante de alojamiento y recursos económicos: Es posible que se te solicite mostrar comprobantes de alojamiento, como una reserva de hotel, o una carta de invitación de un familiar o amigo en Estados Unidos, junto con evidencia de recursos económicos suficientes para cubrir tus gastos durante la estadía.</p><br/>
        <p>- Seguro de viaje: Aunque no es un requisito obligatorio, se recomienda adquirir un seguro de viaje que cubra cualquier emergencia médica o contratiempo durante tu estadía en Estados Unidos. Los servicios médicos en Estados Unidos son costosos, y contar con un seguro de viaje te brinda tranquilidad y protección en caso de necesitar asistencia médica.</p><br/>
        <p>- Cumplir con las normas de aduana: Antes de ingresar a Estados Unidos, asegúrate de revisar las restricciones y regulaciones aduaneras para evitar problemas. No está permitido ingresar ciertos productos, como alimentos frescos, plantas o productos animales sin la debida autorización. Asegúrate de declarar cualquier artículo restringido o prohibido que estés llevando contigo.</p><br/>
    
      </div>
    ),
  },
  {
    question: "¿Cómo elegir el mejor seguro de viaje internacional?",
    answer: (
      <div>
      <p>- Cobertura médica adecuada: Verifica que el seguro de viaje ofrezca una cobertura médica suficiente para cubrir tus necesidades personales. Considera factores como la duración del viaje, el destino y cualquier condición médica preexistente que tengas.</p><br/>
      <p>-Cobertura geográfica: Asegúrate de que el seguro de viaje cubra todos los destinos que tienes en mente. Algunas pólizas pueden tener restricciones para ciertos países o regiones.</p><br/>
      <p>- Política de cancelación: Lee detenidamente los términos y condiciones de cancelación del seguro de viaje. Asegúrate de comprender las circunstancias en las que puedes recibir un reembolso en caso de cancelación.</p><br/>
      <p>- Valoraciones y opiniones: Investiga y lee las opiniones de otros viajeros sobre las compañías de seguros que estás considerando. Esto te dará una idea de su reputación y servicio al cliente.</p><br/>
  
    </div>
    ),
  },
 
];

export default faqs;
